* {
  padding: 0px;
  margin: 0px;
  font-family: "Roboto", sans-serif !important;
}
/* html {
  scroll-behavior: smooth;
} */
html,
body {
  scroll-behavior: auto; /* Reset to default */
}

.App-header {
  /* background-color: #000000; */
  min-height: 100vh;
  color: white;
  /* background: "linear-gradient(108deg, #172231 0%, #030507 50%, #030507 70%)"; */
  /* background: url("./components/images/Noise.png"); */
}
.App-header::after {
  content: "";
  background: "linear-gradient(108deg, #172231 0%, #030507 50%, #030507 70%)";
  height: 100%;
}

.borderBottomCss {
  /* background-image: linear-gradient(to left, #35df91, #178f59, #178f59); */
  background-image: linear-gradient(to bottom, #0073c5 0%, #33a9fe 25%, #35df91 50%, #178f59 90%, #178f59 100%);
  background-size: 100% 4px;
  background-position: bottom;
  background-repeat: no-repeat;
}

.cursorPointer {
  cursor: pointer;
}

.borderCss {
  background: linear-gradient(#000000, #000000) padding-box, linear-gradient(to top, #0073c5 0%, #33a9fe 25%, #35df91 50%, #178f59 90%, #178f59 100%) border-box;
  border-radius: 25px;
  border: 4px solid transparent;
  /* overflow: hidden; */
}

.gradientText {
  background: linear-gradient(to top, #0073c5 0%, #33a9fe 25%, #35df91 50%, #178f59 90%, #178f59 100%);
  -webkit-background-clip: text;
  color: transparent;
}

.normalColor {
  color: #fff;
}
